import React from "react";
import { navigate } from "gatsby";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import dayjs from "dayjs";
import SEO from "../seo";

export default () => {
  const [state, setState] = React.useState({
    data: null,
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    username: "",
    userTitle: "",
    submitButtonDisabled: false,
    currentUrl: "",
    stripeKey: "",
    quoteId: "",
    errorIsHidden: true,
    loading: true,
  });

  React.useEffect(() => {
    setState((s) => ({ stripeKey: process.env.GATSBY_STRIPE_API_KEY }));
    const quoteId = window.location.search.substr(1);
    /* API({
      method: "GET",
      url: `Quotes/${quoteId}`,
    })
      .then((_data) => {
        setState((s) => ({
          ...s,
          data: _data,
          loading: false,
        }));
      })
      .catch(() => {
        navigate("/quote-error");
      });*/
  }, []);

  const handleSubmit = React.useCallback(
    (event) => {
      event.preventDefault();
      let productId = "";
      if (state.data.stripeSku === "YEARLY_M")
        productId = process.env.GATSBY_YEARLY_M;
      else if (state.data.stripeSku === "YEARLY_L")
        productId = process.env.GATSBY_YEARLY_L;
      else if (state.data.stripeSku === "YEARLY_TL")
        productId = process.env.GATSBY_YEARLY_TL;
      else if (state.data.stripeSku === "YEARLY_TEST")
        productId = process.env.GATSBY_YEARLY_TEST;
      else if (state.data.stripeSku === "Yearly2199M")
        productId = process.env.GATSBY_YEARLY_2199;
      else if (state.data.stripeSku === "Yearly2599M")
        productId = process.env.GATSBY_YEARLY_2599;

      setState((s) => ({ ...s, submitButtonDisabled: true }));
      /*API({
        method: "POST",
        url: `Quotes/${state.data.id}/sign`,
        data: { username: state.username, userTitle: state.userTitle },
      })
        .then(async (_data) => {
          setState((s) => ({ ...s, submitButtonDisabled: false }));

          const { error } = await stripe.redirectToCheckout({
            items: [
              {
                plan: productId,
                quantity: state.data.numLicenses,
              },
            ],
            successUrl: `https://labnotebook.app/my-quote/PaymentCompleted?quoteId=${state.data.id}`,
            cancelUrl: `https://labnotebook.app/quotes/quote?${state.data.id}`,
            customerEmail: state.data.email,
          });
          if (error) {
            console.warn("Error:", error);
          }
        })
        .catch((error) => {
          console.warn("Error", error);
          setState((s) => ({ ...s, submitButtonDisabled: false }));
        });*/
    },
    [state]
  );

  return (
    <>
      <SEO
        title="LabLog ELN: Get Quote"
        description="Use this form to lookup your customized quote."
      />

      {!state.data ? (
        <Container
          maxWidth="md"
          style={{
            position: "relative",
            margin: "0 auto",
            textAlign: "center",
            clear: "left",
            height: "auto",
            zIndex: "1000",
          }}
        >
          <br />
          <CircularProgress />
          <br />
          Please wait while we find your agreement
        </Container>
      ) : (
        <Container
          maxWidth="md"
          style={{
            paddingTop: "30px",
            backgroundColor: "#FFFFFF",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 3px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <p>
                LabLog
                <br />
                Aiderbotics Corporation
                <br />
                Tax ID: 81-3947253
                <br />
                support@labnotebook.app
              </p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                textAlign="right"
                component="h1"
                m={0}
                fontWeight="700"
                fontSize="h4.fontSize"
                color="#8686ac"
              >
                <strong>Invoice</strong>
              </Box>
              <div
                id="payButton"
                style={{
                  float: "right",
                }}
              >
                <p>
                  Invoice #: {state.data.id}
                  <br />
                  Invoice date: &nbsp;
                  {dayjs(state.data.createdAt).format("MMM DD YYYY")}
                  <br />
                  Due date: &nbsp;
                  {dayjs(state.data.dueDate).format("MMM DD YYYY")}
                </p>
                {state.data && state.data.paid ? (
                  <p style={{ color: "#8686ac" }} id="paymentConfirmation">
                    <strong>
                      Payment received
                      <br />
                      {dayjs(state.data.paymentDate).format("MMM DD YYYY")}
                    </strong>
                  </p>
                ) : (
                  <Button
                    variant="contained"
                    disabled={state.submitButtonDisabled}
                    onClick={handleSubmit}
                    type="submit"
                    size="large"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pay
                    Invoice&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>

          <Typography component="div">
            <br />

            <p>
              <span style={{ fontSize: "large" }}>
                <strong>Bill To:</strong>
              </span>
              <br />
              {state.data.customer}
              <br />
              {state.data.address}
              <br />
            </p>
            <hr />
            <p>
              <strong>Contact:</strong> {state.data.contact}
              <br />
              <strong>Phone:</strong> {state.data.phone}
              <br />
              <strong>E-mail:</strong> {state.data.email}
              <br />
            </p>
            <hr />
            <p>
              <strong>Services:</strong>
              <br />
              The LabLog mobile app, the LabLog web app, and other products,
              services and websites hosted or made available by LabLog,
              including, for example our User Forum, and Help & Learning pages
              are collectively referred to in these Terms as the "Service(s)".
              <br />
            </p>
            <hr />
            <p>
              <strong>Service Fees:</strong> $
              <u>
                {parseInt(state.data.serviceFee) / 100}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </u>{" "}
              per user per month
              <br />
              <strong>Number of users:</strong>{" "}
              <u>
                &nbsp;&nbsp;
                {state.data.numLicenses}
                &nbsp;&nbsp;
              </u>
              <br />
              <strong>Initial Service Term:</strong> One Year
              <br />
            </p>
            <hr />
            <p>
              <strong>Implementation Services:</strong> LabLog will use
              commercially reasonable efforts to provide
              {state.data.customer} with installation, account setup, and
              training (“Implementation Services”), and
              {state.data.customer} shall pay Company the Implementation Fee in
              accordance with the terms herein.
              <br />
              <strong>Implementation Fee (one-time):</strong> $
              <u>
                {parseInt(state.data.implementationFee) / 100}
                &nbsp;&nbsp;&nbsp;&nbsp;
              </u>
            </p>
          </Typography>
          <br />
          <br />

          <br />
          <br />
          <div id="agreement" style={{ display: "none" }}>
            <br />
            <br />
            <hr />
            <Box
              textAlign="left"
              component="h1"
              id="service-agreement"
              m={1}
              fontFamily="DM Serif Text"
              fontWeight="700"
              fontSize="h3.fontSize"
            >
              Services Agreement
            </Box>
            <br />
            <br />
            <p>
              This SaaS Services Agreement (“Agreement”) is entered into on this{" "}
              <u>
                &nbsp;&nbsp;
                {new Date().getDate()}
                th&nbsp;&nbsp;
              </u>{" "}
              day of{" "}
              <u>
                &nbsp;&nbsp;
                {state.monthNames[new Date().getMonth()]}
                &nbsp;&nbsp;
              </u>
              , {new Date().getFullYear()} (the “Effective Date”) between
              Aiderbotics Corporation with a place of business at{" "}
              <u>1201 Seven Locks Road Rockville, MD 20854</u> (“Company”), and
              the Customer listed above (“Customer”). This Agreement includes
              and incorporates the above Order Form, as well as the attached
              Terms and Conditions and contains, among other things, warranty
              disclaimers, liability limitations and use limitations. There
              shall be no force or effect to any different terms of any related
              purchase order or similar form even if signed by the parties after
              the date hereof.
            </p>
            <br />
            <strong>{state.data.customer}</strong>
            <br />
            <br />
            <form onSubmit={handleSubmit}>
              <input
                onChange={(e) => {
                  setState((s) => ({ ...s, username: e.target.value }));
                }}
                placeholder="Type your name here"
                required
                id="name-input"
              />
              <br />
              <br />
              <input
                placeholder="Your title"
                required
                id="title-input"
                onChange={(e) => {
                  setState((s) => ({ ...s, userTitle: e.target.value }));
                }}
              />
              <br />
              <br />
              <p>
                Type your name above to indicate that you have read and agree to{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lablogapp.blob.core.windows.net/external-files/LabLog_Terms_and_Conditions.pdf"
                  download
                >
                  LabLog Terms and Conditions
                </a>
                .
              </p>
              <br />
              <Button
                variant="contained"
                disabled={state.submitButtonDisabled}
                type="submit"
                size="large"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  float: "right",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Next:
                Payment&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            </form>
            <br />
            <br />
          </div>

          <br />
        </Container>
      )}
    </>
  );
};
